<template>
  <div>
    <CRow>
      <CCol col>
        <CCard>
          <CCardHeader>
            <strong> Modulo de Caja - Ver</strong>
          </CCardHeader>
          <CCardBody>

              <b-form id="Form" @submit.prevent="Validate">
                  <b-row>
                      <b-col md="3">
                          <b-form-group label="Codigo:">
                          <b-form-input type="text" class="text-center" readonly v-model="box.code" ></b-form-input>
                          </b-form-group>
                      </b-col>

                      <b-col md="3">
                          <b-form-group label="Fecha Inicio:">
                          <b-form-input type="date" class="text-center" readonly v-model="box.start_date" ></b-form-input>
                          </b-form-group>
                      </b-col>

                      <b-col md="3">
                          <b-form-group label="Fecha Final:">
                          <b-form-input readonly type="date" :min="box.start_date" class="text-center" v-model="box.end_date" ></b-form-input>
                          </b-form-group>
                      </b-col>

                      <b-col md="3">
                          <b-form-group label="Usuario:">
                          <b-form-input type="text" class="text-center" readonly v-model="box.user" ></b-form-input>
                          </b-form-group>
                      </b-col>

                      <b-col md="10">
                        <b-form-group>
                          <label class="control-label">Observación: </label>
                          <b-form-textarea readonly v-model="box.observation" rows="2"></b-form-textarea>                 
                        </b-form-group>
                      </b-col>

                      <b-col md="2">
                        <b-form-group>
                          <label class="control-label">Estado: </label>
                          <b-form-select disabled v-model="box.state" :options="state"></b-form-select>                 
                        </b-form-group>
                      </b-col>


                      <b-col md="12"></b-col>
                      <b-col md="6">
                        
                        <div class="w-100 p-2 text-center"><strong class="header">RESUMEN DE VENTAS</strong></div>
                        <div class="table-responsive">
                          <table class="table table-striped table-bordered w-100">
                            <thead>
                                <tr class="bg-danger">
                                  <td class="text-center" width="40%" ></td>
                                  <td class="text-center" width="20%">SOLES</td>
                                  <td class="text-center" width="20%">DOLARES</td>
                                  <td class="text-center" width="20%">PESOS</td>
                                </tr>
                            </thead>
                            <tbody>
                                
                                <tr class="bg-info">
                                  <td class="text-let pl-3" >Ventas al Contado</td>
                                  <td class="text-right" >{{box.total_counted_sales_pen}}</td>
                                  <td class="text-right" >{{box.total_counted_sales_usd}}</td>
                                  <td class="text-right" >{{box.total_counted_sales_clp}}</td>
                                </tr>
                                <tr class="bg-light text-dark">
                                  <td class="text-let pl-5" >Efectivo</td>
                                  <td class="text-right" >{{box.cash_pen}}</td>
                                  <td class="text-right" >{{box.cash_usd}}</td>
                                  <td class="text-right" >{{box.cash_clp}}</td>
                                </tr>
                                <tr class="bg-light text-dark">
                                  <td class="text-let pl-5" >Tarjeta</td>
                                  <td class="text-right" >{{box.debit_pen}}</td>
                                  <td class="text-right" >{{box.debit_usd}}</td>
                                  <td class="text-right" >{{box.debit_clp}}</td>
                                </tr>
                                
                                <!-- <tr class="bg-light text-dark">
                                  <td class="text-let pl-5" >Tarjeta de Crédito</td>
                                  <td class="text-right" >{{box.credit_pen}}</td>
                                  <td class="text-right" >{{box.credit_usd}}</td>
                                  <td class="text-right" >{{box.credit_clp}}</td>
                                </tr> -->
                                <tr class="bg-light text-dark">
                                  <td class="text-let pl-5" >Transferencia de Fondos</td>
                                  <td class="text-right" >{{box.transfer_funds_pen}}</td>
                                  <td class="text-right" >{{box.transfer_funds_usd}}</td>
                                  <td class="text-right" >{{box.transfer_funds_clp}}</td>
                                </tr>
                                <tr class="bg-light text-dark">
                                  <td class="text-let pl-5" >Yape</td>
                                  <td class="text-right" >{{box.yape_pen}}</td>
                                  <td class="text-right" >{{box.yape_usd}}</td>
                                  <td class="text-right" >{{box.yape_clp}}</td>
                                </tr>
                                <tr class="bg-light text-dark">
                                  <td class="text-let pl-5" >Plin</td>
                                  <td class="text-right" >{{box.pilin_pen}}</td>
                                  <td class="text-right" >{{box.pilin_usd}}</td>
                                  <td class="text-right" >{{box.pilin_clp}}</td>
                                </tr>
                                <tr class="bg-light text-dark">
                                  <td class="text-let pl-5" >Vale de Consumo</td>
                                  <td class="text-right" >{{box.others_pen}}</td>
                                  <td class="text-right" >{{box.others_usd}}</td>
                                  <td class="text-right" >{{box.others_clp}}</td>
                                </tr>
                                <tr class="bg-light text-dark">
                                  <td class="text-let pl-5" >Creditos al Personal</td>
                                  <td class="text-right" >{{box.credits_staff_pen}}</td>
                                  <td class="text-right" >{{box.credits_staff_usd}}</td>
                                  <td class="text-right" >{{box.credits_staff_clp}}</td>
                                </tr>
                                <tr class="bg-info">
                                  <td class="text-let pl-3" >Ventas al Credito</td>
                                  <td class="text-right" >{{box.total_credit_sales_pen}}</td>
                                  <td class="text-right" >{{box.total_credit_sales_usd}}</td>
                                  <td class="text-right" >{{box.total_credit_sales_clp}}</td>
                                </tr>
                                <tr class="bg-success">
                                  <td class="text-let" >Total de Ventas del Periodo</td>
                                  <td class="text-right" >{{box.total_sales_period_pen}}</td>
                                  <td class="text-right" >{{box.total_sales_period_usd}}</td>
                                  <td class="text-right" >{{box.total_sales_period_clp}}</td>
                                </tr>
                            </tbody>
                          </table>
                        </div>
                      </b-col>


                      <b-col md="6">
                        <div class="w-100 p-2 text-center"><strong class="header">ESTADO DE CAJA</strong></div>
                        <div class="table-responsive">
                          <table class="table table-striped table-bordered w-100">
                            <thead>
                                <tr class="bg-danger">
                                  <td class="text-center" width="55%" ></td>
                                  <td class="text-center" width="15%">SOLES</td>
                                  <td class="text-center" width="15%">DOLARES</td>
                                  <td class="text-center" width="15%">PESOS</td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr class="bg-success">
                                  <td class="text-let" >Saldo Inicial</td>
                                  <td class="text-right" >{{box.initial_balance_pen}}</td>
                                  <td class="text-right" >{{box.initial_balance_usd}}</td>
                                  <td class="text-right" >{{box.initial_balance_clp}}</td>
                                </tr>
                                <tr class="bg-success">
                                  <td class="text-let" >Ingresos por Ventas al Contado - Efectivo</td>
                                  <td class="text-right" >{{box.cash_pen}}</td>
                                  <td class="text-right" >{{box.cash_usd}}</td>
                                  <td class="text-right" >{{box.cash_clp}}</td>
                                </tr>
                                <tr class="bg-success">
                                  <td class="text-let" >Ingresos por cobros de Cuentas - Efectivo</td>
                                  <td class="text-right" >{{box.total_income_collections_pen}}</td>
                                  <td class="text-right" >{{box.total_income_collections_usd}}</td>
                                  <td class="text-right" >{{box.total_income_collections_clp}}</td>
                                </tr>
                                <tr class="bg-success">
                                  <td class="text-let" >Egreso por pagos de Cuentas - Efectivo</td>
                                  <td class="text-right" >{{box.total_payment_expenses_pen}}</td>
                                  <td class="text-right" >{{box.total_payment_expenses_usd}}</td>
                                  <td class="text-right" >{{box.total_payment_expenses_clp}}</td>
                                </tr>
                                <tr class="bg-success">
                                  <td class="text-let" >Ingresos Extraordinarios - Efectivo</td>
                                  <td class="text-right" >{{box.total_extraordinary_income_pen}}</td>
                                  <td class="text-right" >{{box.total_extraordinary_income_usd}}</td>
                                  <td class="text-right" >{{box.total_extraordinary_income_clp}}</td>
                                </tr>
                                <tr class="bg-success">
                                  <td class="text-let" >Egresos Extraordinarios - Efectivo</td>
                                  <td class="text-right" >{{box.total_extraordinary_expenses_pen}}</td>
                                  <td class="text-right" >{{box.total_extraordinary_expenses_usd}}</td>
                                  <td class="text-right" >{{box.total_extraordinary_expenses_clp}}</td>
                                </tr>
                                <tr class="bg-dark">
                                  <td class="text-let" >Saldo Final</td>
                                  <td class="text-right" >{{box.total_income_pen}}</td>
                                  <td class="text-right" >{{box.total_income_usd}}</td>
                                  <td class="text-right" >{{box.total_income_clp}}</td>
                                </tr>
                            </tbody>
                          </table>
                        </div>
                      </b-col>

                      <b-col md="3"></b-col>
                      <b-col md="2">
                          <b-button type="button" @click="ModalBoxSummaryShow" class="form-control" variant="info">Resumen de Caja</b-button>
                      </b-col>
                      <b-col md="2">
                          <b-button type="button" @click="ExportExcel" class="form-control" variant="success">Excel</b-button>
                      </b-col>
                      <b-col md="2">
                          <b-link class="btn form-control btn-primary" :to="{ path: '/caja/listar' }" append >REGRESAR</b-link>
                      </b-col>
                  </b-row>
              </b-form>

          
        
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>

    <BoxSummary />
  </div>
</template>

<script>
const axios = require("axios").default;
const Swal = require("sweetalert2");
const je = require("json-encrypt");
import { mapState } from "vuex";
var moment = require("moment");
import EventBus from "@/assets/js/EventBus";
import BoxSummary from './components/Summary'
export default {
  name: "CashEdit",
  components:{
    BoxSummary,
  },
  props: ["id_box"],
  data() {
    return {
      module: "Box",
      role: 3,
      state:[
        {value:'1',text:'Pendiente'},
        {value:'2',text:'Finalizado'},
        {value:'0',text:'Anulado'},
      ],
       box: {
          id_box:0,
          id_user:0,
          id_correlative:0,
          cash_number:'',
          start_date:'',
          end_date:'',

          initial_balance_pen:(0).toFixed(2),
          cash_pen:(0).toFixed(2),
          deposit_account_pen:(0).toFixed(2),
          debit_pen:(0).toFixed(2),
          credit_pen:(0).toFixed(2),
          transfer_funds_pen:(0).toFixed(2),
          yape_pen:(0).toFixed(2),
          pilin_pen:(0).toFixed(2),
          others_pen:(0).toFixed(2),
          credits_staff_pen:(0).toFixed(2),
          total_counted_sales_pen:(0).toFixed(2),
          total_credit_sales_pen:(0).toFixed(2),
          total_sales_period_pen:(0).toFixed(2),
          total_income_collections_pen:(0).toFixed(2),
          total_payment_expenses_pen:(0).toFixed(2),
          total_extraordinary_income_pen:(0).toFixed(2),
          total_extraordinary_expenses_pen:(0).toFixed(2),
          total_income_pen:(0).toFixed(2),

          initial_balance_usd:(0).toFixed(2),
          cash_usd:(0).toFixed(2),
          deposit_account_usd:(0).toFixed(2),
          debit_usd:(0).toFixed(2),
          credit_usd:(0).toFixed(2),
          transfer_funds_usd:(0).toFixed(2),
          yape_usd:(0).toFixed(2),
          pilin_usd:(0).toFixed(2),
          others_usd:(0).toFixed(2),
          credits_staff_usd:(0).toFixed(2),
          total_counted_sales_usd:(0).toFixed(2),
          total_credit_sales_usd:(0).toFixed(2),
          total_sales_period_usd:(0).toFixed(2),
          total_income_collections_usd:(0).toFixed(2),
          total_payment_expenses_usd:(0).toFixed(2),
          total_extraordinary_income_usd:(0).toFixed(2),
          total_extraordinary_expenses_usd:(0).toFixed(2),
          total_income_usd:(0).toFixed(2),

          initial_balance_clp:(0).toFixed(2),
          cash_clp:(0).toFixed(2),
          deposit_account_clp:(0).toFixed(2),
          debit_clp:(0).toFixed(2),
          credit_clp:(0).toFixed(2),
          transfer_funds_clp:(0).toFixed(2),
          yape_clp:(0).toFixed(2),
          pilin_clp:(0).toFixed(2),
          others_clp:(0).toFixed(2),
          credits_staff_clp:(0).toFixed(2),
          total_counted_sales_clp:(0).toFixed(2),
          total_credit_sales_clp:(0).toFixed(2),
          total_sales_period_clp:(0).toFixed(2),
          total_income_collections_clp:(0).toFixed(2),
          total_payment_expenses_clp:(0).toFixed(2),
          total_extraordinary_income_clp:(0).toFixed(2),
          total_extraordinary_expenses_clp:(0).toFixed(2),
          total_income_clp:(0).toFixed(2),

          observation:'',
          state:0,
          

      },
    };
  },
  mounted() {
    this.ViewBox();
  },
  methods: {
    ViewBox,
    EditBox,
    Validate,

    ExportExcel,
    ExportPDF,

    ModalBoxSummaryShow

  },

  computed: {
    ...mapState(["url_base"]),
    token: function () {
      let user = window.localStorage.getItem("user");
      user = JSON.parse(JSON.parse(je.decrypt(user)));
      return user.api_token;
    },
    user: function () {
      let user = window.localStorage.getItem("user");
      user = JSON.parse(JSON.parse(je.decrypt(user)));
      return user;
    },
  },
};

function ExportExcel() {
  let me = this;
  let url = this.url_base + "box-export-excel/"+this.box.id_box;
  window.open(url,'_blank');
}

function ModalBoxSummaryShow() {

  let data = {
    role: this.role,
    id_box: this.box.id_box,
  };
  EventBus.$emit('ModalBoxSummaryShow',data);
  
}

function ExportPDF() {

  let me = this;
  let url = this.url_base + "cash-export-pdf/"+this.cash.id_box+'/'+this.cash.end_date;
  
  window.open(url,'_blank');
}
function ViewBox() {
  let id_box = je.decrypt(this.id_box);
  let me = this;
  let url = this.url_base + "box/view/"+id_box;

  axios({
    method: "GET",
    url: url,
    headers: {token: this.token,module: this.module,role: this.role},
  })
    .then(function (response) {
      if (response.data.status == 200) {
          
          me.box.id_box = response.data.result.id_box;
          me.box.id_user = response.data.result.id_user;
          me.box.code = response.data.result.code;
          me.box.start_date = response.data.result.start_date;
          me.box.end_date = response.data.result.end_date;
          me.box.observation = response.data.result.observation;
          me.box.user = response.data.result.user;
          me.box.state = response.data.result.state;

          me.box.initial_balance_pen = response.data.result.initial_balance_pen;
          me.box.cash_pen = response.data.result.cash_pen;
          me.box.deposit_account_pen = response.data.result.deposit_account_pen;
          me.box.debit_pen = response.data.result.debit_pen;
          me.box.credit_pen = response.data.result.credit_pen;
          me.box.transfer_funds_pen = response.data.result.transfer_funds_pen;
          me.box.yape_pen = response.data.result.yape_pen;
          me.box.pilin_pen = response.data.result.pilin_pen;
          me.box.others_pen = response.data.result.others_pen;
          me.box.credits_staff_pen = response.data.result.credits_staff_pen;
          me.box.total_counted_sales_pen = response.data.result.total_counted_sales_pen;
          me.box.total_credit_sales_pen = response.data.result.total_credit_sales_pen;
          me.box.total_sales_period_pen = response.data.result.total_sales_period_pen;
          me.box.total_income_collections_pen = response.data.result.total_income_collections_pen;
          me.box.total_payment_expenses_pen = response.data.result.total_payment_expenses_pen;
          me.box.total_extraordinary_income_pen = response.data.result.total_extraordinary_income_pen;
          me.box.total_extraordinary_expenses_pen = response.data.result.total_extraordinary_expenses_pen;
          me.box.total_income_pen = response.data.result.total_income_pen;

          me.box.initial_balance_usd = response.data.result.initial_balance_usd;
          me.box.cash_usd = response.data.result.cash_usd;
          me.box.deposit_account_usd = response.data.result.deposit_account_usd;
          me.box.debit_usd = response.data.result.debit_usd;
          me.box.credit_usd = response.data.result.credit_usd;
          me.box.transfer_funds_usd = response.data.result.transfer_funds_usd;
          me.box.yape_usd = response.data.result.yape_usd;
          me.box.pilin_usd = response.data.result.pilin_usd;
          me.box.others_usd = response.data.result.others_usd;
          me.box.credits_staff_usd = response.data.result.credits_staff_usd;
          me.box.total_counted_sales_usd = response.data.result.total_counted_sales_usd;
          me.box.total_credit_sales_usd = response.data.result.total_credit_sales_usd;
          me.box.total_sales_period_usd = response.data.result.total_sales_period_usd;
          me.box.total_income_collections_usd = response.data.result.total_income_collections_usd;
          me.box.total_payment_expenses_usd = response.data.result.total_payment_expenses_usd;
          me.box.total_extraordinary_income_usd = response.data.result.total_extraordinary_income_usd;
          me.box.total_extraordinary_expenses_usd = response.data.result.total_extraordinary_expenses_usd;
          me.box.total_income_usd = response.data.result.total_income_usd;


          me.box.initial_balance_clp = response.data.result.initial_balance_clp;
          me.box.cash_clp = response.data.result.cash_clp;
          me.box.deposit_account_clp = response.data.result.deposit_account_clp;
          me.box.debit_clp = response.data.result.debit_clp;
          me.box.credit_clp = response.data.result.credit_clp;
          me.box.transfer_funds_clp = response.data.result.transfer_funds_clp;
          me.box.yape_clp = response.data.result.yape_clp;
          me.box.pilin_clp = response.data.result.pilin_clp;
          me.box.others_clp = response.data.result.others_clp;
          me.box.credits_staff_clp = response.data.result.credits_staff_clp;
          me.box.total_counted_sales_clp = response.data.result.total_counted_sales_clp;
          me.box.total_credit_sales_clp = response.data.result.total_credit_sales_clp;
          me.box.total_sales_period_clp = response.data.result.total_sales_period_clp;
          me.box.total_income_collections_clp = response.data.result.total_income_collections_clp;
          me.box.total_payment_expenses_clp = response.data.result.total_payment_expenses_clp;
          me.box.total_extraordinary_income_clp = response.data.result.total_extraordinary_income_clp;
          me.box.total_extraordinary_expenses_clp = response.data.result.total_extraordinary_expenses_clp;
          me.box.total_income_clp = response.data.result.total_income_clp;
      }
    })
}






function EditBox() {
  let me = this;
  let url = this.url_base + "box/edit";
  let data = this.box;
  axios({
    method: "PUT",
    url: url,
    data: data,
    headers: {"Content-Type": "application/json",token:this.token,module:this.module,role:this.role},
  })
    .then(function (response) {
      if (response.data.status == 200) {
         Swal.fire({ icon: 'success', text: response.data.message , timer: 3000,})
      } else {
         Swal.fire({ icon: 'error', text: response.data.message , timer: 3000,})
      }
    })
    .catch((error) => {
      Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
    });
}

function Validate() {

  let me = this;
    Swal.fire({
      title: 'Esta seguro de modificar la caja?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, Estoy de Acuerdo!'
    }).then((result) => {
      if (result.isConfirmed) {
        me.EditBox();
      }
    })


}


</script>
